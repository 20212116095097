import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Container } from './styles';

interface IUnidadeProps {
  imagem: any;
  nome: string;
  localizacao: string;
  enderecoUnidade: string;
  slug: string;
}

const Unidade = ({
  imagem,
  nome,
  localizacao,
  enderecoUnidade,
  slug,
}: IUnidadeProps): JSX.Element => {
  const image = useMemo(
    () =>
      getImage(
        imagem.imageFile.childImageSharp.gatsbyImageData
      ) as IGatsbyImageData,
    [imagem.imageFile]
  );

  return (
    <Container>
      {image && <GatsbyImage image={image} alt={`Foto da unidade ${nome}`} />}

      <div className="content">
        <h2>{nome}</h2>
        <p>{localizacao}</p>
        <p>{enderecoUnidade}</p>

        <Link to={slug}>Saiba mais</Link>
      </div>
    </Container>
  );
};

export default Unidade;
