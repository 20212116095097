import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div`
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 327px;
  height: 424px;

  .gatsby-image-wrapper {
    min-height: 190px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  div.content {
    border: 1px solid #bfd3f6;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    height: 100%;
    padding: 21px;

    background-color: white;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    width: 100%;
    max-width: 327px;

    &:hover {
      background-color: ${colors.blue100};
      transition: 0.15;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-top: 5px;
    font-size: 16px;
    color: ${colors.gray400};
    max-width: 267px;
  }

  a {
    margin-top: auto;

    background-color: ${colors.blue400};

    outline: none;
    border: none;

    border-radius: 8.4px;
    height: 40px;

    width: 100%;
    max-width: 140px;
    color: white;

    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 320px) {
    width: 300px;

    div.content {
      max-width: 300px;
    }
  }
`;
