import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { graphql, useStaticQuery } from 'gatsby';
import * as yup from 'yup';
import axios from 'axios';

import { getValidationErrors } from '@/utils/getValidationErrors';
import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import Unidade from '@/components/Pages/Unidades/Unidade';

import SearchIcon from '@/images/icons/icon-search.svg';
import ShowAllSvg from '@/images/icons/show-all.svg';
import SearchIconDisabled from '@/images/icons/icon-search-disabled.svg';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  HeaderSection,
  HeaderForm,
  HeaderInput,
  HeaderSearchButton,
  Spinner,
  ShowAllContainer,
  UnidadesSectionWrapper,
  UnidadesSection,
} from '../styles/pages/unidades';
import { CMS_URL } from '@/config';

interface IUnidadeRaw {
  id: string;
  image: any;
  name: string;
  localizacao: string;
  address: string;
  slug: string;
  latitude: string;
  longitude: string;
}

interface IUnidade {
  id: string;
  imagem: any;
  nome: string;
  localizacao: string;
  enderecoUnidade: string;
  slug: string;
  latitude: string;
  longitude: string;
}

interface IFormData {
  cep: string;
}

interface ILatitudeLongitude {
  latitude: string;
  longitude: string;
}

interface IPosition {
  latitude: number;
  longitude: number;
}

interface IGetDistance {
  latitude: string;
  longitude: string;
  unidades: IUnidade[];
}

type IDistancia = {
  id: string;
  distancia: number;
};

function calcDistancia(position1: IPosition, position2: IPosition) {
  // https://www.geodatasource.com/developers/javascript

  if (
    position1.latitude == position2.latitude &&
    position1.longitude == position2.longitude
  ) {
    return 0;
  } else {
    const radlat1 = (Math.PI * position1.latitude) / 180;
    const radlat2 = (Math.PI * position2.latitude) / 180;
    const theta = position1.longitude - position2.longitude;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    const distInKM = dist * 1.609344;

    return distInKM;
  }
}

function getDistance({ latitude, longitude, unidades }: IGetDistance) {
  const arrDistancia: IDistancia[] = [];

  unidades.forEach((unidade) => {
    const latitudeOrigem = Number(latitude);
    const longitudeOrigem = Number(longitude);
    const latitudeDestino = Number(unidade.latitude);
    const longitudeDestino = Number(unidade.longitude);

    if (
      !latitudeOrigem ||
      !longitudeOrigem ||
      !latitudeDestino ||
      !longitudeDestino
    ) {
      return;
    }

    const distancia = calcDistancia(
      {
        latitude: latitudeOrigem,
        longitude: longitudeOrigem,
      },
      {
        latitude: latitudeDestino,
        longitude: longitudeDestino,
      }
    );

    if (distancia) {
      arrDistancia.push({
        id: unidade.id,
        distancia,
      });
    }
  });

  arrDistancia.sort((a, b) => a.distancia - b.distancia);

  return {
    filterTodasMaisProximas: arrDistancia,
    filterQuatroMaisProximas: arrDistancia.slice(0, 4),
  };
}

function getUnidadeComponent(unidades: IUnidade[]) {
  return unidades ? (
    unidades.map((unidade: IUnidade) => {
      return (
        <Unidade
          key={unidade.id}
          imagem={unidade.imagem}
          nome={unidade.nome}
          slug={unidade.slug}
          localizacao={unidade.localizacao}
          enderecoUnidade={unidade.enderecoUnidade}
        />
      );
    })
  ) : (
    <h2>Erro ao buscar unidade. Tente novamente mais tarde.</h2>
  );
}

const UnidadesPage = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const { directus } = useStaticQuery(query);

  const [cep, setCep] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasGeolocation, setHasGeoLocation] = useState(false);
  const [filterType, setFilterType] = useState<'all' | 'fourMostRecent'>('all');

  const [
    filteredDistanceQuatroMaisProximas,
    setFilteredDistanceQuatroMaisProximas,
  ] = useState<IDistancia[]>([]);

  const [
    filteredDistanceTodasMaisProximas,
    setFilteredDistanceTodasMaisProximas,
  ] = useState<IDistancia[]>([]);

  const unidades = useMemo((): IUnidade[] => {
    return directus.branches.map((unidade: IUnidadeRaw) => ({
      id: unidade.id,
      imagem: unidade.image,
      nome: unidade.name,
      localizacao: unidade.localizacao,
      enderecoUnidade: unidade.address,
      slug: unidade.slug,
      latitude: unidade.latitude,
      longitude: unidade.longitude,
    }));
  }, [directus.branches]);

  useEffect(() => {
    if (hasGeolocation) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { filterTodasMaisProximas, filterQuatroMaisProximas } =
          getDistance({
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
            unidades: unidades,
          });

        setFilterType('all');
        setFilteredDistanceTodasMaisProximas(filterTodasMaisProximas);
        setFilteredDistanceQuatroMaisProximas(filterQuatroMaisProximas);
      });
    }
  }, [hasGeolocation, unidades]);

  useEffect(() => {
    if ('geolocation' in navigator) {
      setHasGeoLocation(true);
    } else {
      setHasGeoLocation(false);
    }
  }, []);

  const filteredUnidades = useMemo((): IUnidade[] => {
    const filteredUnidades: IUnidade[] = [];

    const filteredDistance =
      filterType === 'all'
        ? filteredDistanceTodasMaisProximas
        : filteredDistanceQuatroMaisProximas;

    for (let index = 0; index < filteredDistance.length; index++) {
      const distanciaObj = filteredDistance[index];

      const findIndex = unidades.findIndex(
        (unidade) => unidade.id === distanciaObj.id
      );

      if (findIndex !== -1) {
        filteredUnidades.push(unidades[findIndex]);
      }
    }

    return filteredUnidades;
  }, [
    unidades,
    filteredDistanceQuatroMaisProximas,
    filteredDistanceTodasMaisProximas,
    filterType,
  ]);

  const getLocation = useCallback(async (cep: string) => {
    try {
      const response = await axios.post<ILatitudeLongitude>(
        '/api/cep/localizacao',
        {
          cep,
        }
      );

      const { latitude, longitude } = response.data;

      if (!latitude || !longitude) {
        throw new Error('Erro ao buscar latitude/longitude.');
      }

      return {
        latitude,
        longitude,
      };
    } catch (err) {
      console.error(err);

      throw new Error('Erro ao buscar localização.');
    }
  }, []);

  const handleFormSubmit = useCallback(
    async ({ cep }: IFormData) => {
      const parsedCEP = removeAllNonNumberCharacters(cep);

      const schema = yup.object().shape({
        cep: yup.string().min(8, '*Insira pelo menos 8 dígitos.'),
      });

      try {
        formRef.current?.setErrors({});

        await schema.validate(
          { cep: parsedCEP },
          {
            abortEarly: false,
          }
        );
      } catch (yupErr: any) {
        const errors = getValidationErrors(yupErr);

        formRef.current?.setErrors(errors);
        return;
      }

      setLoading(true);

      try {
        const { latitude, longitude } = await getLocation(parsedCEP);

        const { filterTodasMaisProximas, filterQuatroMaisProximas } =
          getDistance({
            latitude,
            longitude,
            unidades: unidades,
          });

        setFilterType('fourMostRecent');
        setFilteredDistanceTodasMaisProximas(filterTodasMaisProximas);
        setFilteredDistanceQuatroMaisProximas(filterQuatroMaisProximas);
      } catch (error: any) {
        formRef.current?.setErrors({
          cep: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [unidades, getLocation]
  );

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Unidades"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <HeaderSection>
                <h1>Encontre as unidades Generoso mais próximas de você!</h1>

                <HeaderForm
                  ref={formRef}
                  onSubmit={handleFormSubmit}
                  autoComplete="new-password"
                >
                  <HeaderInput
                    name="cep"
                    placeholder="Digite seu CEP..."
                    labelName="CEP"
                    type="tel"
                    mask="99999-999"
                    setInputValue={setCep}
                    defaultValue={cep}
                  />

                  <HeaderSearchButton
                    type="submit"
                    disabled={!cep || loading}
                    loading={loading}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <>
                        Buscar{' '}
                        <img
                          src={cep ? SearchIcon : SearchIconDisabled}
                          alt="Lupa de pesquisa"
                        />
                      </>
                    )}
                  </HeaderSearchButton>
                </HeaderForm>

                <ShowAllContainer>
                  {filterType === 'fourMostRecent' && (
                    <button
                      type="button"
                      onClick={() => {
                        setFilterType('all');
                      }}
                    >
                      <img src={ShowAllSvg} />
                      Mostrar todas
                    </button>
                  )}
                </ShowAllContainer>
              </HeaderSection>
            </SectionWrapper>

            <UnidadesSectionWrapper>
              <UnidadesSection>
                {getUnidadeComponent(
                  filteredUnidades.length === 0 ? unidades : filteredUnidades
                )}
              </UnidadesSection>
            </UnidadesSectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default UnidadesPage;

const query = graphql`
  query {
    directus {
      branches {
        id
        name
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        localizacao
        address
        slug
        latitude
        longitude
      }

      site_pages(filter: { slug: { _contains: "unidades" } }) {
        seo {
          title
          description
          keywords
          image {
            id
          }
        }
      }
    }
  }
`;
