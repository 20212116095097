import styled, { keyframes } from 'styled-components';
import { Form } from '@unform/web';

import DefaultInput from '@/components/Inputs/DefaultInput';

import { colors } from '../GlobalStyle';

interface IHeaderSearchButtonProps {
  loading: boolean;
}

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  background-color: ${colors.gray100};

  height: fit-content;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1440px;

  height: fit-content;
  min-height: 70vh;
`;

export const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 1380px;

  height: fit-content;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 268px;

  background-color: white;

  h1 {
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    max-width: 405px;
    text-align: center;
    margin: 0px;
  }

  border-bottom: 1px solid ${colors.gray200};

  @media (max-width: 499px) {
    min-height: 300px;

    h1 {
      max-width: 300px;
    }
  }

  @media (max-width: 320px) {
    h1 {
      font-size: 19px;
      max-width: 260px;
    }
  }
`;

export const HeaderForm = styled(Form)`
  margin-top: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 592px;

  @media (max-width: 499px) {
    flex-direction: column;

    padding: 0 16px;
  }
`;

export const HeaderInput = styled(DefaultInput)`
  width: 100%;
  max-width: 200px;

  outline: none;

  height: 56px;
  border-radius: 8px;
  margin-right: 16px;
  margin-top: 0;

  color: ${colors.gray600};

  outline: none;

  input {
    height: 56px;

    ::placeholder {
      color: ${colors.gray600};
    }
  }

  > span {
    position: relative;
    top: 10px;
  }

  div {
    svg {
      top: 18px;
    }

    span {
      height: 20px;
    }
  }

  @media (max-width: 499px) {
    margin-right: 0;
  }
`;

export const HeaderSearchButton = styled.button<IHeaderSearchButtonProps>`
  width: 100%;
  max-width: 150px;

  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.loading ? 'center' : 'space-between')};

  height: 56px;

  padding: 16px 34px;

  border: none;
  border-radius: 8px;

  background-color: ${colors.blue400};
  color: ${colors.white};
  font-weight: medium;
  font-size: 16px;
  line-height: 25px;

  transition: background-color 0.3s;

  :disabled {
    background-color: ${colors.gray200};
    color: ${colors.gray400};
  }

  &:hover {
    background-color: ${colors.blue500};
    transition: 0.3s;
  }

  @media (max-width: 499px) {
    margin-top: 8px;
    max-width: 200px;
  }
`;

export const Spinner = styled.div`
  border: 4px solid ${colors.white};
  border-top: 4px solid ${colors.blue400};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${rotate} 0.6s linear infinite;
`;

export const ShowAllContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 592px;
  margin-top: 24px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;
    width: 100%;
    max-width: 366px;

    border-radius: 8px;

    border: none;
    outline: none;
    background-color: ${colors.blue400};

    color: ${colors.white};

    &:hover {
      background-color: ${colors.blue500};
      transition: 0.3s;
    }

    img {
      height: 24px;
      width: 24px;

      margin-right: 8px;
    }
  }
`;

export const UnidadesSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  max-width: 1380px;

  height: 100%;
  min-height: 800px;

  margin: 24px 0;
`;

export const UnidadesSection = styled.main`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;

  flex-wrap: wrap;

  width: 100%;
  height: 100%;

  @media (max-width: 1396px) {
    width: 1030px;

    > div {
      margin-left: 0px;
    }
  }

  @media (max-width: 1030px) {
    width: 700px;
  }

  @media (max-width: 700px) {
    width: 400px;
    justify-content: center;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
